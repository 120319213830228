import { render, staticRenderFns } from "./CustomerExpire.vue?vue&type=template&id=6e37fcc7&scoped=true&"
import script from "./CustomerExpire.vue?vue&type=script&lang=js&"
export * from "./CustomerExpire.vue?vue&type=script&lang=js&"
import style0 from "./CustomerExpire.vue?vue&type=style&index=0&id=6e37fcc7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e37fcc7",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCardTitle,VIcon,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/cplug-fid-front-customer/cplug-fid-front-customer/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6e37fcc7')) {
      api.createRecord('6e37fcc7', component.options)
    } else {
      api.reload('6e37fcc7', component.options)
    }
    module.hot.accept("./CustomerExpire.vue?vue&type=template&id=6e37fcc7&scoped=true&", function () {
      api.rerender('6e37fcc7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/home/components/customerSummary/CustomerExpire.vue"
export default component.exports