<template>
   <v-skeleton-loader v-if="loading && vouchers.length" type="card"></v-skeleton-loader>
    <div v-else >
        <!-- Mobile -->
        <v-col v-if="$vuetify.breakpoint.smAndDown">
            <v-col class="white card-radius" v-if="vouchers && vouchers.length">
                <v-row>
                    <VoucherCard
                        class="mb-3"
                        style="width: 100%"
                        v-for="(voucher, index) in vouchers"
                        :key="index"
                        :voucher="voucher"
                        :showActions="true"
                        :showDescription="false"
                        :prizeHeightImg="140"
                        :showDivier="false"
                        :showPrice="false"
                    />
                </v-row>
            </v-col>
            <v-col>
                <div v-if="vouchers && vouchers.length" @click="showPrizes">
                    <div class="primary--text text-center body-2 clickable">{{ $t('views.HomeView.history.show_all') }}</div>
                </div>
                <div v-else @click="showPrizes">
                    <div class="text-center clickable">{{ $t('views.HomeView.voucher_withrdrawal.no_voucher') }}</div>
                </div>
            </v-col>
        </v-col>
        
        <!-- Desktop -->
        <v-card v-else class="grow pa-0 background red" elevation="0">
            <v-list class="background">
                <VoucherHistoryItem
                    class="card card-radius white"
                    v-for="(voucher, index) in vouchers"
                    :key="index"
                    :item="voucher"
                />

                <v-list-item v-if="vouchers && vouchers.length" class="mt-3 show-records clickable" @click="showPrizesWithdrawable">
                    <v-list-item-content>
                        <div class="primary--text text-center body-2">{{ $t('views.HomeView.history.show_all') }}</div>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-else class="mt-3" @click="showPrizesWithdrawable">
                    <v-list-item-content>
                        <span class="w-100">{{ $t('views.HomeView.voucher_withrdrawal.no_voucher') }}</span>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    
    </div>
</template>

<script>
import Voucher from '@/models/Voucher'
import VoucherHistoryItem from './VoucherHistoryItem'
import VoucherCard from '@/components/cards/VoucherCard'
import { mapGetters } from 'vuex'

export default {
    name: 'VoucherHistory',
    components: { VoucherHistoryItem, VoucherCard },
    data: vm => ({
        loading: false,
        vouchers: [],
    }),
    async created() {
        await this.fetchVouchers()
    },
    methods: {
        showPrizes() {
            this.$router.push({ name: 'prizes' })
        },
        showPrizesWithdrawable() {
            this.$router.push({ name: 'prizes', query: { tab: 'withdrawable' } })
        },
        async fetchVouchers(pagination) {
            if (!this.user)
                return

            this.loading = true
            const params = { per_page: 4, page: 1, order: 'DESC', withdrawable: 'TRUE' }
            const headers = { 'customer-document': this.user.document }

            const response = await Voucher.list({ ...pagination, ...params}, { headers })

            if(!response) {
                this.loading = false
                return
            }

            this.vouchers = response
            this.loading = false 
        },
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        }),
    },
}
</script>

<style scoped>
.card {
    height: 62px;
}
.card-radius {
    border-radius: .5rem;
}
</style>