var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "card pl-md-2", attrs: { outlined: "", height: "110px" } },
    [
      _c("v-card-title", { staticClass: "subtitle-1 pb-0" }, [
        _vm._v(_vm._s(_vm.$t("views.HomeView.expire.title")))
      ]),
      _c(
        "v-card-text",
        [
          _c("v-row", { staticClass: "d-flex align-center ml-1" }, [
            _c("div", { staticClass: "d-flex align-center card-content" }, [
              _c(
                "div",
                { staticClass: "expire-days d-flex" },
                [
                  _c("ExpiresCard", {
                    staticClass: "pr-0 ml-n1",
                    attrs: { days: 7 }
                  }),
                  _c("ExpiresCard", {
                    staticClass: "pr-0",
                    attrs: { days: 30 }
                  }),
                  _c("ExpiresCard", {
                    staticClass: "pr-0",
                    attrs: { days: 90 }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "trade-points mr-2 d-flex justify-end",
                  on: { click: _vm.trandePoints }
                },
                [
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-center" },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "bag-icon mt-1",
                            attrs: { size: 35, color: "primary" }
                          },
                          [_vm._v("$cp_bag")]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "text-center primary--text" }, [
                      _vm._v(_vm._s(_vm.$t("views.HomeView.expire.prizes")))
                    ])
                  ])
                ]
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }