var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        {
          class: _vm.$vuetify.breakpoint.smAndDown
            ? "d-flex flex-column-reverse"
            : ""
        },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex flex-column px-2",
              attrs: { cols: "12", md: _vm.hideCashback ? "12" : "8" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "title mb-3 ml-4 font-weight-bold primary--text"
                },
                [_vm._v(_vm._s(_vm.$t("views.HomeView.history.title")))]
              ),
              _c("HistoryCard", { staticClass: "mt-n2" })
            ],
            1
          ),
          !_vm.hideCashback
            ? _c(
                "v-col",
                {
                  staticClass: "d-flex flex-column pl-1",
                  attrs: { cols: "12", md: "4" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "title mb-3 ml-4 font-weight-bold primary--text"
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("views.HomeView.voucher_withrdrawal.title")
                        )
                      )
                    ]
                  ),
                  _c("VoucherHistory", { staticClass: "mt-n2" })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }