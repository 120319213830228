<template>
    <v-skeleton-loader v-if="loading" type="card"></v-skeleton-loader>
    <v-card v-else class="grow pa-0 background" elevation="0">
    <!-- <v-card v-else height="100%"> -->
        <v-list class="background">
            <!-- Infos -->

            <!-- [TODO] - FID-1094 -->
            <!-- <v-list-item v-if="displayMode == 'POINTS'">
                <v-list-item-content>
                    <div class="subtitle-1">{{ $t('views.HomeView.history.received_amount') }}</div>
                </v-list-item-content>
                <v-list-item-action>
                    <b>{{ $tc('globals.amount_points', { amount: received_amount }) }}</b>
                </v-list-item-action>
            </v-list-item> -->

            <!-- Registros mais recentes -->
            <div v-if="items && items.length">
                <HistoryDialog class="card white" v-for="(item, index) in items" :key="index" :item="item"/>
            </div>

            <!-- Mostrar tudo -->

            <v-list-item v-if="items && items.length" class="mt-3 show-records" @click="goHistories">
                <v-list-item-content>
                    <div class="primary--text text-center body-2">{{ $t('views.HomeView.history.show_all') }}</div>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-else class="mt-3">
                <v-list-item-content>
                    <span class="w-100">{{ $t('views.HomeView.history.no_registers') }}</span>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card>
</template>
<script>
import Consumption from '@/models/Consumption'
import Generator from '@/models/Generator'
import Reversal from '@/models/Reversal'
import HistoryDialog from '@/shared/components/dialogs/HistoryDialog'
import { mapGetters } from 'vuex'

export default {
    name: 'HistoryCard',
    data: vm => ({
        items          : [],
        spent_value    : 0,
        received_amount: 0,
        loading        : true,
    }),
    components: { HistoryDialog },
    async created() {
        await this.fetchData()
    },
    methods: {
        goHistories() {
            this.$router.push({ name: 'history' })
        },
        async fetchData() {
            this.loading = true
            
            let response = await this.$axios({
                url: 'customer/history',
                method: 'get',
                params: {
                    per_page         : 4,
                    page             : 1,
                    order            : 'DESC',
                    include_reversals: true,
                }
            })

            if (!response || response.status != 200) {
                this.loading = false
                return
            }

            // Transforma em modelos
            let modelItems = []

            response.data.forEach(register => {
                // Todas e somente consumptions tem intention_id
                // Todas e somente reversal tem generator_id
                // Isso será usado para diferencia-las de generators
                let Model = register.intention_id ? Consumption : register.source_id ? Reversal : Generator

                modelItems.push(new Model(register))
            })
            
            this.items   = modelItems
            this.loading = true

            response = await this.$axios({
                url   : 'customer/balance',
                method: 'get',
            })

            if (!response || response.status != 200) {
                this.loading = false
                return
            }

            this.spent_value     = response.data.spent_value || 0
            this.received_amount = response.data.received_amount || 0

            this.loading = false
        },
    },
    computed: {
        ...mapGetters({
            displayMode: 'company/display_mode'
        })
    }
}
</script>

<style scoped>
.card {
    border-radius: .5rem;
}
.show-records {
    cursor: pointer;
}
</style>