var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading && _vm.vouchers.length
    ? _c("v-skeleton-loader", { attrs: { type: "card" } })
    : _c(
        "div",
        [
          _vm.$vuetify.breakpoint.smAndDown
            ? _c(
                "v-col",
                [
                  _vm.vouchers && _vm.vouchers.length
                    ? _c(
                        "v-col",
                        { staticClass: "white card-radius" },
                        [
                          _c(
                            "v-row",
                            _vm._l(_vm.vouchers, function(voucher, index) {
                              return _c("VoucherCard", {
                                key: index,
                                staticClass: "mb-3",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  voucher: voucher,
                                  showActions: true,
                                  showDescription: false,
                                  prizeHeightImg: 140,
                                  showDivier: false,
                                  showPrice: false
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-col", [
                    _vm.vouchers && _vm.vouchers.length
                      ? _c("div", { on: { click: _vm.showPrizes } }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "primary--text text-center body-2 clickable"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("views.HomeView.history.show_all")
                                )
                              )
                            ]
                          )
                        ])
                      : _c("div", { on: { click: _vm.showPrizes } }, [
                          _c("div", { staticClass: "text-center clickable" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "views.HomeView.voucher_withrdrawal.no_voucher"
                                )
                              )
                            )
                          ])
                        ])
                  ])
                ],
                1
              )
            : _c(
                "v-card",
                {
                  staticClass: "grow pa-0 background red",
                  attrs: { elevation: "0" }
                },
                [
                  _c(
                    "v-list",
                    { staticClass: "background" },
                    [
                      _vm._l(_vm.vouchers, function(voucher, index) {
                        return _c("VoucherHistoryItem", {
                          key: index,
                          staticClass: "card card-radius white",
                          attrs: { item: voucher }
                        })
                      }),
                      _vm.vouchers && _vm.vouchers.length
                        ? _c(
                            "v-list-item",
                            {
                              staticClass: "mt-3 show-records clickable",
                              on: { click: _vm.showPrizesWithdrawable }
                            },
                            [
                              _c("v-list-item-content", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "primary--text text-center body-2"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "views.HomeView.history.show_all"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        : _c(
                            "v-list-item",
                            {
                              staticClass: "mt-3",
                              on: { click: _vm.showPrizesWithdrawable }
                            },
                            [
                              _c("v-list-item-content", [
                                _c("span", { staticClass: "w-100" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "views.HomeView.voucher_withrdrawal.no_voucher"
                                      )
                                    )
                                  )
                                ])
                              ])
                            ],
                            1
                          )
                    ],
                    2
                  )
                ],
                1
              )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }