<template>
    <div v-if="shouldShowBanner">
        <!-- <v-container class="mb-3"> -->
            <a :href="banner.promo_url" target="_blank">
                <v-img :aspect-ratio="223/59" :src="banner.photo_url"></v-img>
            </a>
        <!-- </v-container> -->
    </div>
</template>

<script>
import Promotion from '@/models/Promotion'
import _ from 'lodash'

export default {
    name: 'Banner',
    data() {
        return {
            banner: null,
        }
    },
    methods: {
        async searchBanner() {
            let results = await Promotion.banner({ params: { per_page: 1 } })
            this.banner = this.$lodash.first(results)
        },
    },
    created() {
        this.searchBanner()
    },
    computed: {
        shouldShowBanner() {
            return _.get(this.banner, 'photo_url')
        },
    },
}
</script>
