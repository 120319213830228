import { render, staticRenderFns } from "./VoucherHistory.vue?vue&type=template&id=2712f09c&scoped=true&"
import script from "./VoucherHistory.vue?vue&type=script&lang=js&"
export * from "./VoucherHistory.vue?vue&type=script&lang=js&"
import style0 from "./VoucherHistory.vue?vue&type=style&index=0&id=2712f09c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2712f09c",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCard,VCol,VList,VListItem,VListItemContent,VRow,VSkeletonLoader})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/cplug-fid-front-customer/cplug-fid-front-customer/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2712f09c')) {
      api.createRecord('2712f09c', component.options)
    } else {
      api.reload('2712f09c', component.options)
    }
    module.hot.accept("./VoucherHistory.vue?vue&type=template&id=2712f09c&scoped=true&", function () {
      api.rerender('2712f09c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/home/components/voucherHistory/VoucherHistory.vue"
export default component.exports