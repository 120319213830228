<template>
    <v-list-item class="my-3" :class="$vuetify.breakpoint.smAndDown ? 'pa-1':''">
        <v-list-item-content :class="$vuetify.breakpoint.smAndDown ? 'pl-3':''">
            <v-list-item-title class="title"> {{ item.prize.name }} </v-list-item-title>
            <template>
                <span class="mr-4 secondary_font--text font-weight-regular item-text">{{ getTitle }}</span>
            </template>
        </v-list-item-content>

        <div class="d-flex flex-column mx-my-auto mr-2 py-1">
            <span class="secondary_font--text font-weight-regular item-text">{{ $t('globals.code_withdrawal') }}</span>
            <v-chip class="px-3 mt-1 align-self-center" color="primary" label outlined>
                <span class="primary--text font-weight-bold">{{ item.getCodeString() }}</span>
            </v-chip>
        </div>
    </v-list-item>
</template>

<script>

import HasSlot from '@/shared/mixins/HasSlot'

export default {
    name: 'voucherHistoryItem',
    mixins: [ HasSlot ],
    props: {
        item: {
            required: true,
        }
    },
    computed: {
        getTitle() {
            if(!this.item.expires_at)
                return this.$t('globals.available_forever')

            return this.$vuetify.breakpoint.lgAndUp ? 
            this.$t('globals.available_withdrawal', { date: this.$options.filters.date(this.item.expires_at) }) :
            this.$t('globals.available_withdrawal_short', { date: this.$options.filters.date(this.item.expires_at) })
        }
    }
}
</script>

<style>
.v-chip.v-size--default {
    height: 28px !important;
}

.voucher-img{
    height: 74px;
    object-fit: fill !important;
}
</style>
