<template>
    <v-row>  
        <!-- Show only small screens -->
        <section class="d-flex d-none d-sm-flex d-md-none pa-0 sx-amount mt-1">
             <div class="d-flex" height="30">
                <div class="d-flex align-start mr-4">
                    <v-icon class="mr-2 mt-2" color="#9A9CB8" :size="15">$cp_dollar</v-icon>
                    <div class="d-flex flex-column">
                        <span class="secondary_font--text points-text">
                        {{ $t('views.HomeView.levels.level_balance.amount_balance.label') }} 
                        </span>
                        <span class="caption primary--text amount">
                            {{ $tc('views.HomeView.levels.level_balance.amount_balance.value', amountBalance )}}
                        </span>
                    </div>
                </div>
                    
                <div class="d-flex align-start">
                    <v-icon class="mr-2 mt-2" color="#9A9CB8" :size="15">$cp_gift</v-icon>
                    <div class="d-flex flex-column">
                        <span class="secondary_font--text points-text">
                        {{ $t('views.HomeView.levels.level_balance.spendt_amount.label') }} 
                        </span>
                        <span class="caption primary--text amount">
                            {{ $tc('views.HomeView.levels.level_balance.amount_balance.value', spendtAmount )}}
                        </span>
                    </div>
                </div>
            </div>

            <div class="primary--text show-levels sx-show-levels d-flex align-center" @click="showLevels">
                <div class="ml-auto">{{ $t('views.HomeView.levels.show_levels') }}</div>
            </div>
        </section>

        <!-- Show desktop screen -->
        <section class="d-none d-md-flex d-lg-flex pa-0 md-amount">
            <div class="d-flex" height="30">
                <div class="d-flex align-start justify-end icon-content px-2">
                    <v-icon class="mr-2 mt-2" color="#9A9CB8" :size="iconSize">$cp_dollar</v-icon>
                    <div class="d-flex flex-column">
                        <span class="secondary_font--text points-text">
                        {{ $t('views.HomeView.levels.level_balance.amount_balance.label') }} 
                        </span>
                        <span class="caption primary--text amount">
                            {{ $tc('views.HomeView.levels.level_balance.amount_balance.value' , amountBalance )}}
                        </span>
                    </div>
                </div>
                <div class="d-flex align-start justify-start icon-content">
                    <v-icon class="mr-2 mt-2" color="#9A9CB8" :size="iconSize">$cp_gift</v-icon>
                    <div class="d-flex flex-column">
                        <span class="secondary_font--text points-text">
                        {{ $t('views.HomeView.levels.level_balance.spendt_amount.label') }} 
                        </span>
                        <span class="caption primary--text amount">
                            {{ $tc('views.HomeView.levels.level_balance.amount_balance.value', spendtAmount )}}
                        </span>
                    </div>
                </div>
            </div>
        

            <div class="primary--text text-center show-levels px-0 mt-2" 
                :class="this.$vuetify.breakpoint.lgAndUp ? 'mt-4' : ''" @click="showLevels"
                >{{ $t('views.HomeView.levels.show_levels') }}
            </div>
        </section>
    </v-row>
</template>

<script>
export default {
    name: 'CustomerLevelAmountIcon',
    data: vm => ({
        error         : false,
        loading       : true,
    }),
    async created() {
        this.fetchBalence()
    },
    methods: {
        showLevels() {
            this.$router.push({ name: 'levels' })
        },
        async fetchBalence() {
            this.loading = true
            this.error   = false

            const response = await this.$axios({
                url: 'customer/balance',
                method: 'get',
            }).catch(this.preErrorHandler)

            if (!response || !response.data || response.status != 200) return

            response.data.recived_amount = response.data.recived_amount || 0
            response.data.spent_amount   = response.data.spent_amount || 0

            this.amountBalance = response.data.amount_balance
            this.spendtAmount  = response.data.spent_amount
            this.loading       = false
        },
        preErrorHandler(e) {
            this.loading = false
            this.error   = true
        },
    },
    computed: {
        iconSize() {
            return this.$vuetify.breakpoint.lgAndUp ? 20 : 15
        },
        amountBalance: {
            get() {
                return this.$store.getters['balance/amountBalance']
            },
            set(v) {
                this.$store.commit('balance/SET_AMOUNT_BALANCE', v)
            },
        },
        spendtAmount: {
            get() {
                return this.$store.getters['balance/spendtAmount']
            },
            set(v) {
                this.$store.commit('balance/SET_SPENDT_AMOUNT', v)
            }
        }
    }
}
</script>

<style scoped>
.amount {
    margin-top: -0.5725rem;
}
.show-levels {
    cursor: pointer;
}
.points-text {
    font-size: 0.5625rem;
}
.icon {
    margin-top: 0.625rem;
}
.icon-content{
    display: flex;
    flex: 1;
}
.sx-amount {
   display: flex;
   flex: 1;
   flex-direction: row;
}
.sx-show-levels {
    display: flex;
    flex: 1;
}
.md-amount {
   display: flex;
   flex: 1;
   flex-direction: column;
}

.row {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

@media (min-width: 1264px) { 
    .points-text {
        font-size: .8125rem;
        margin-bottom: 2px;
    }

    .caption{
        font-size: .825rem !important;
    }
 }
</style>