var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.shouldShowBanner
    ? _c("div", [
        _c(
          "a",
          { attrs: { href: _vm.banner.promo_url, target: "_blank" } },
          [
            _c("v-img", {
              attrs: { "aspect-ratio": 223 / 59, src: _vm.banner.photo_url }
            })
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }