var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "background", attrs: { outlined: "" } },
    [
      _c(
        "v-card-text",
        { staticClass: "px-3 pt-4" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12", md: "12" } },
                [
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "subtitle-2 secondary_font--text mb-1" },
                      [_vm._v(_vm._s(_vm.$t("views.HomeView.home")))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "section-title font-weight-bold primary_font--text"
                      },
                      [_vm._v(_vm._s(_vm.user ? _vm.user.name : ""))]
                    )
                  ])
                ]
              ),
              !_vm.hidePoints
                ? _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center",
                      attrs: { cols: "12", sm: "12", md: "2" }
                    },
                    [_c("CustomerBalance", { staticClass: "mr-2 mb-2" })],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                {
                  staticClass: "pb-0",
                  class: _vm.$vuetify.breakpoint.smAndDown ? "ml-2" : "",
                  attrs: { cols: "12", sm: "12", md: _vm.hidePoints ? 12 : 6 }
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "mr-1 pa-0" },
                    [_vm.hasLevels ? _c("CustomerLevel") : _vm._e()],
                    1
                  )
                ],
                1
              ),
              !_vm.hidePoints
                ? _c(
                    "v-col",
                    {
                      staticClass: "card-expire",
                      class: _vm.$vuetify.breakpoint.smAndDown
                        ? "pa-0 px-1 ml-n1 mt-4"
                        : "",
                      attrs: { cols: "12", sm: "12", md: "4" }
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ml-n1 d-flex" },
                        [
                          _c("CustomerExpire", {
                            staticClass: "pb-1",
                            staticStyle: { width: "100%" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }