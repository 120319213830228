var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CurrencyCard", {
    staticClass: "mx-auto",
    attrs: {
      loading: _vm.loading,
      valueTotal: _vm.valueTotal,
      amountTotal: _vm.amountTotal,
      underline: _vm.$t("views.HomeView.expire.overline", { days: _vm.days }),
      "max-width": "300"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }