<template>
    <div v-bind="attrs" v-on="listeners">
        <div class="text-md-left d-flex flex-column-reverse">
            <v-skeleton-loader type="heading" v-if="loading"></v-skeleton-loader>
            <v-col v-else class="pa-0">
                <span v-show="displayMode != 'POINTS'">{{ valueTotal | currency }}</span>
            </v-col>
            <div v-if="underline">
                <span class="primary--text font-weight-bold">{{ underline }}</span>
                <v-col v-if="displayMode == 'POINTS'" class="pa-0">
                    {{ amountTotal | dynamicPoint }}
                </v-col>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'CurrencyCard',
    props: {
        valueTotal: Number,
        amountTotal: Number,
        underline: String,
        bold: Boolean,
        loading: Boolean,
    },
    computed: {
        ...mapGetters({
            displayMode: 'company/display_mode',
        }),
        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { ...listeners } = this.$listeners
            return listeners
        },

        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { valueTotal, amountTotal, underline, bold, loading, ...attrs } = this.$attrs
            return { ...attrs }
        },
    },
}
</script>