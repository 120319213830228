<template>
    <div>
        <v-row :class="$vuetify.breakpoint.smAndDown ? 'd-flex flex-column-reverse':''">
            <v-col cols="12" :md="hideCashback ? '12' : '8'" class="d-flex flex-column px-2">
                <div class="title mb-3 ml-4 font-weight-bold primary--text">{{ $t('views.HomeView.history.title') }}</div>
                <HistoryCard class="mt-n2" />
            </v-col>
            <v-col v-if="!hideCashback" cols="12" md="4" class="d-flex flex-column pl-1">
                <div class="title mb-3 ml-4 font-weight-bold primary--text">{{ $t('views.HomeView.voucher_withrdrawal.title') }}</div>
                <VoucherHistory class="mt-n2" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HistoryCard from './components/HistoryCard'
import VoucherHistory from './components/voucherHistory/VoucherHistory'

export default {
    name: 'DashboardView',
    components: {
        HistoryCard,
        VoucherHistory,
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            hasLevels: 'levels/hasLevels',
            hideCashback: 'company/hide_cashback',
        }),
    },
}
</script>