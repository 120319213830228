import { render, staticRenderFns } from "./HistoryCard.vue?vue&type=template&id=3ff7bc9e&scoped=true&"
import script from "./HistoryCard.vue?vue&type=script&lang=js&"
export * from "./HistoryCard.vue?vue&type=script&lang=js&"
import style0 from "./HistoryCard.vue?vue&type=style&index=0&id=3ff7bc9e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ff7bc9e",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCard,VList,VListItem,VListItemContent,VSkeletonLoader})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/cplug-fid-front-customer/cplug-fid-front-customer/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3ff7bc9e')) {
      api.createRecord('3ff7bc9e', component.options)
    } else {
      api.reload('3ff7bc9e', component.options)
    }
    module.hot.accept("./HistoryCard.vue?vue&type=template&id=3ff7bc9e&scoped=true&", function () {
      api.rerender('3ff7bc9e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/home/components/HistoryCard.vue"
export default component.exports