var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", _vm._g(_vm._b({}, "div", _vm.attrs, false), _vm.listeners), [
    _c(
      "div",
      { staticClass: "text-md-left d-flex flex-column-reverse" },
      [
        _vm.loading
          ? _c("v-skeleton-loader", { attrs: { type: "heading" } })
          : _c("v-col", { staticClass: "pa-0" }, [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.displayMode != "POINTS",
                      expression: "displayMode != 'POINTS'"
                    }
                  ]
                },
                [_vm._v(_vm._s(_vm._f("currency")(_vm.valueTotal)))]
              )
            ]),
        _vm.underline
          ? _c(
              "div",
              [
                _c("span", { staticClass: "primary--text font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.underline))
                ]),
                _vm.displayMode == "POINTS"
                  ? _c("v-col", { staticClass: "pa-0" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("dynamicPoint")(_vm.amountTotal)) +
                          " "
                      )
                    ])
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }