<template>
    <div>
        <Banner />
        <v-container>
            <CustomerSummary />

        </v-container>
        <!-- <v-divider></v-divider> -->
        <v-container class="pt-0">
            <!-- Child route -->
            <router-view></router-view>

        </v-container>
    </div>
</template>

<script>
import CustomerSummary from './components/customerSummary/CustomerSummary'
import RateColor       from '@/shared/mixins/RateColor'
import Banner          from './Banner'

export default {
    name: 'HomeView',
    mixins: [ RateColor ],
    components: {
        CustomerSummary, Banner
    },
    data: vm => ({
        spent_value: null,
        received_amount: null,
    }),
    methods: {
        fillTotals(balance) {
            this.spent_value = balance.spent_value
            this.received_amount = balance.received_amount
        },
        goBack() {
            this.$router.go(-1)
        },
    },
    computed: {
        shouldShowBackLink() {
            return this.$route.name != 'home.dashboard'
        },
    },
}
</script>

<style>
  .v-application a {
    color: #212121 !important;
  }
</style>

<style scoped>
    .pointer {
        cursor: pointer;
    }
</style>