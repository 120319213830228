<template>
    <CurrencyCard
        :loading="loading" 
        :valueTotal="valueTotal"
        :amountTotal="amountTotal"
        :underline="$t('views.HomeView.expire.overline', { days })"
        max-width="300" class="mx-auto"
    />
</template>
<script>
import CurrencyCard from './CurrencyCard'
import { roundNumber } from '@/utils/mathUtils'

export default {
    name: 'ExpiresCard',
    components: { CurrencyCard },
    props: {
        days: {
            required: true,
            type: Number,
        },
    },
    async created() {
        await this.fetchData()
    },
    data: vm => ({
        valueTotal: 0,
        amountTotal: 0,
        loading: true,
        error: false,
    }),
    methods: {
        async fetchData() {
            this.loading = true

            let response = await this.$axios({
                url: 'points',
                method: 'get',
                params: {
                    per_page      : this.$constants.getConstant('PAGINATION_MAX_PER_PAGE'),
                    days_to_expire: this.days,
                }
            })

            this.loading = false

            if (!response || !response.data || response.status != 200) {
                this.error = true
                return
            }
            
            let points = response.data.data
            this.valueTotal = points ? points.reduce((carry, current) => Number(roundNumber(carry + current.value)), 0) : 0
            this.amountTotal = points ? points.reduce((carry, current) => Number(roundNumber(carry + current.amount)), 0) : 0

            this.loading = false
        },
    },
}
</script>