var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("v-skeleton-loader", { attrs: { type: "card" } })
    : _c(
        "v-card",
        { staticClass: "grow pa-0 background", attrs: { elevation: "0" } },
        [
          _c(
            "v-list",
            { staticClass: "background" },
            [
              _vm.items && _vm.items.length
                ? _c(
                    "div",
                    _vm._l(_vm.items, function(item, index) {
                      return _c("HistoryDialog", {
                        key: index,
                        staticClass: "card white",
                        attrs: { item: item }
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm.items && _vm.items.length
                ? _c(
                    "v-list-item",
                    {
                      staticClass: "mt-3 show-records",
                      on: { click: _vm.goHistories }
                    },
                    [
                      _c("v-list-item-content", [
                        _c(
                          "div",
                          { staticClass: "primary--text text-center body-2" },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("views.HomeView.history.show_all"))
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                : _c(
                    "v-list-item",
                    { staticClass: "mt-3" },
                    [
                      _c("v-list-item-content", [
                        _c("span", { staticClass: "w-100" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("views.HomeView.history.no_registers")
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }