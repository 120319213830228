<template>
    <v-card class="background" outlined>
        <v-card-text class="px-3 pt-4">
            <v-row>
                <v-col cols="12" md="12" class="pa-0">
                    <div>
                        <div class="subtitle-2 secondary_font--text mb-1">{{ $t('views.HomeView.home') }}</div>
                        <div class="section-title font-weight-bold primary_font--text">{{ user ? user.name : '' }}</div>
                    </div>
                </v-col>

                 <v-col v-if="!hidePoints" cols="12" sm="12" md="2" class="d-flex align-center">
                    <CustomerBalance class="mr-2 mb-2" />
                </v-col>

                <v-col cols="12" sm="12" :md="hidePoints ? 12 : 6" class="pb-0" :class="$vuetify.breakpoint.smAndDown ? 'ml-2': ''"> 
                    <v-row class="mr-1 pa-0">
                        <CustomerLevel v-if="hasLevels" />
                    </v-row>
                </v-col>

                <v-col v-if="!hidePoints" cols="12" sm="12" md="4" class="card-expire" :class="$vuetify.breakpoint.smAndDown ? 'pa-0 px-1 ml-n1 mt-4': ''" >
                    <v-row class="ml-n1 d-flex">
                        <CustomerExpire class="pb-1" style="width: 100%" />
                     </v-row>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import CustomerLevel from './CustomerLevel'
import CustomerExpire from './CustomerExpire'
import CustomerBalance from '@/components/structure/balance/CustomerBalance'
import { mapGetters } from 'vuex'

export default {
    name: 'CustomerSummary',
    components: { CustomerLevel, CustomerExpire, CustomerBalance },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            hasLevels: 'levels/hasLevels',
            hidePoints: 'company/hide_points',
        }),
    },
}
</script>

<style scoped>
.balance{
    max-width: 8rem;
}

.card-expire {
    width: 100% !important;
}
</style>