import { render, staticRenderFns } from "./Banner.vue?vue&type=template&id=3ec09f5c&"
import script from "./Banner.vue?vue&type=script&lang=js&"
export * from "./Banner.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VImg})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/cplug-fid-front-customer/cplug-fid-front-customer/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3ec09f5c')) {
      api.createRecord('3ec09f5c', component.options)
    } else {
      api.reload('3ec09f5c', component.options)
    }
    module.hot.accept("./Banner.vue?vue&type=template&id=3ec09f5c&", function () {
      api.rerender('3ec09f5c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/home/Banner.vue"
export default component.exports