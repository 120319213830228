<template>
    <v-card class="card pl-md-2" outlined height="110px">
        <v-card-title class="subtitle-1 pb-0">{{ $t('views.HomeView.expire.title') }}</v-card-title>

        <v-card-text>
            <v-row class="d-flex align-center ml-1">
                <div class="d-flex align-center card-content">
                    <div class="expire-days d-flex">
                        <ExpiresCard class="pr-0 ml-n1" :days="7" />
                        <ExpiresCard class="pr-0"       :days="30"/>
                        <ExpiresCard class="pr-0"       :days="90"/>
                    </div>

                    <div class="trade-points mr-2 d-flex justify-end" @click="trandePoints">
                        <div>
                            <div class="d-flex justify-center">
                                <v-icon 
                                    class="bag-icon mt-1"
                                    :size="35" 
                                    color="primary"
                                >$cp_bag</v-icon>
                            </div>
                            <div class="text-center primary--text">{{ $t('views.HomeView.expire.prizes') }}</div>
                        </div>
                    </div>
                </div>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import ExpiresCard from '../expire/ExpiresCard'

export default {
    components: { ExpiresCard },
    methods: {
        trandePoints() {
            this.$router.push({ name: 'prizes' })
        }
    },
}
</script>

<style scoped>
.theme--light.v-card.v-card--outlined {
    border-radius: .5rem;
}
.card{
    position: relative;
    border: solid 1px var(--v-primary-base) !important;
    z-index: 1
}
.card::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--v-primary-base);
    opacity: .1;
    border-radius: .5rem;
    z-index: -1;
}
.card-content{
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: space-between !important;
}
.trade-points{
    margin-top: -2rem;
    cursor: pointer;
}
.expire-days{
    width: 90%;
    max-width: 15rem;
}
</style>