var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item",
    {
      staticClass: "my-3",
      class: _vm.$vuetify.breakpoint.smAndDown ? "pa-1" : ""
    },
    [
      _c(
        "v-list-item-content",
        { class: _vm.$vuetify.breakpoint.smAndDown ? "pl-3" : "" },
        [
          _c("v-list-item-title", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.item.prize.name) + " ")
          ]),
          [
            _c(
              "span",
              {
                staticClass:
                  "mr-4 secondary_font--text font-weight-regular item-text"
              },
              [_vm._v(_vm._s(_vm.getTitle))]
            )
          ]
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "d-flex flex-column mx-my-auto mr-2 py-1" },
        [
          _c(
            "span",
            {
              staticClass: "secondary_font--text font-weight-regular item-text"
            },
            [_vm._v(_vm._s(_vm.$t("globals.code_withdrawal")))]
          ),
          _c(
            "v-chip",
            {
              staticClass: "px-3 mt-1 align-self-center",
              attrs: { color: "primary", label: "", outlined: "" }
            },
            [
              _c("span", { staticClass: "primary--text font-weight-bold" }, [
                _vm._v(_vm._s(_vm.item.getCodeString()))
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }