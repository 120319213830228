var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c(
        "v-row",
        {
          staticClass: "level-card px-4",
          class: _vm.$vuetify.breakpoint.mdOnly ? "py-5" : ""
        },
        [
          _c("v-col", { staticClass: "pa-0" }, [
            _c(
              "div",
              {
                staticClass:
                  "secondary_font--text d-flex align-center customer-current-level"
              },
              [
                _vm._v(" " + _vm._s(_vm.computedText) + " "),
                _c(
                  "span",
                  {
                    staticClass: "primary--text font-weight-bold mx-1",
                    class: _vm.$vuetify.breakpoint.mdOnly ? "" : "subtitle-1"
                  },
                  [_vm._v(_vm._s(_vm.customerLevel.name))]
                ),
                _c(
                  "v-icon",
                  {
                    staticClass: "align-center mt-n1 level-icon",
                    attrs: { size: 15, color: "primary" }
                  },
                  [_vm._v("far fa-star")]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "caption" },
              [
                _c(
                  "div",
                  {
                    style: _vm.computedProgressStyles(),
                    attrs: { clas: "d-flex" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "primary--text d-flex justify-end" },
                      [_vm._v(_vm._s(_vm.progress + "%"))]
                    )
                  ]
                ),
                _c("v-progress-linear", {
                  staticClass: "progress-liner-styles my-1",
                  attrs: {
                    "background-color": "progress_linear",
                    value: _vm.progress
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-space-between text-capitalize secondary_font--text customer-current-level"
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.progressFromName))]),
                    _c("span", [_vm._v(_vm._s(_vm.progressToName))])
                  ]
                )
              ],
              1
            )
          ]),
          !_vm.hidePoints
            ? _c(
                "v-col",
                {
                  staticClass: "pa-0 ma-0",
                  attrs: { cols: "12", md: "6", sm: "12", xl: "4" }
                },
                [_c("CustomerLevelAmountIcon", { staticClass: "ml-2" })],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }