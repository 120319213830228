<template>
    <v-row class="level-card px-4" :class="$vuetify.breakpoint.mdOnly ? 'py-5': ''" v-if="user">
        <v-col class="pa-0">
            <div class="secondary_font--text d-flex align-center customer-current-level">
                {{ computedText }}
                <span :class="$vuetify.breakpoint.mdOnly ? '': 'subtitle-1'" class="primary--text font-weight-bold mx-1">{{ customerLevel.name }}</span>
                <v-icon :size="15" color="primary" class="align-center mt-n1 level-icon">far fa-star</v-icon>
            </div>
            
            <div class="caption">
                <div clas="d-flex" :style="computedProgressStyles()">
                    <div class="primary--text d-flex justify-end">{{ `${progress}%` }}</div>
                </div>

                <v-progress-linear 
                    background-color="progress_linear"
                    class="progress-liner-styles my-1"
                    :value="progress"
                ></v-progress-linear>
                <div class="d-flex justify-space-between text-capitalize secondary_font--text customer-current-level">
                    <span>{{ progressFromName }}</span> 
                    <span>{{ progressToName }}</span>
                </div>
            </div>
        </v-col>
        <v-col v-if="!hidePoints" cols="12" md="6" sm="12" xl="4" class="pa-0 ma-0">
            <CustomerLevelAmountIcon class="ml-2" />
        </v-col>
    </v-row>
</template>

<script>
import CustomerLevelAmountIcon from './CustomerLevelAmountIcon'
import { mapGetters }          from 'vuex'
import Level                   from '@/models/Level'

export default {
    name: 'CustomerLevel',
    components: { CustomerLevelAmountIcon },
    data: vm => ({
        error        : false,
        loading      : true,
        customerLevel: null,
        defaultLevel : new Level,
    }),
    async created() {
        this.fetchData()
    },
      methods: {
        async fetchData() {
            this.customerLevel = this.consolidatedLevel
        },
        nextLevel() {
            if(!this.customerLevel.next || !this.customerLevel.next.id) return

            const nextLevel = this.levels
                .find(level => level.id === this.customerLevel.next.id)

            if(!nextLevel) return

            return nextLevel.name
        },
        computedProgressStyles() {
            const widthPosition = this.progress

            return {
                'position': 'relative',
                'padding': '0 0 0 22px',
                'width': `${widthPosition}%`,
            }
        }
    },
    computed: {
        ...mapGetters({
            user  : 'auth/user',
            levels: 'levels/levels',
            hidePoints: 'company/hide_points',
        }),
        levelsSystem() {
            return this.$lodash.get(this.user, 'levels_system.current', {})
        },
        consolidatedLevel() {
            return this.$lodash.get(this.user, 'consolidated_level', this.defaultLevel)
        },
        transitionLevel() {
            return this.$lodash.get(this.user, 'transition_level', this.defaultLevel)
        },
        consolidatedAccumulatedAmount() {
            return this.$lodash.get(this.user, 'consolidated_accumulated_amount', 0)
        },
        transitionAccumulatedAmount() {
            return this.$lodash.get(this.user, 'transition_accumulated_amount', 0)
        },
        isLevelGuaranteed() {
            return this.$lodash.isEqual(this.consolidatedLevel, this.transitionLevel)
        },
        progress() {
            if (this.levelsSystem == this.$constants.getConstant('LOOKBACK_LEVELS_SYSTEM')) {
                if (this.transitionLevel && this.transitionLevel.isLast())
                    return 100

                let trigger_amount = this.$lodash.get(this.transitionLevel, 'trigger_amount', 0)
                let r  = this.transitionAccumulatedAmount - trigger_amount
                let rr = this.$lodash.get(this.transitionLevel, 'next.amount', 0) - trigger_amount
                return rr ? Math.floor((r / rr) * 100) : 0
            }

            if (!this.isLevelGuaranteed)
                return Math.floor((this.transitionAccumulatedAmount / this.consolidatedLevel.trigger_amount) * 100)

            if (this.consolidatedLevel && this.consolidatedLevel.isLast())
                return 100

            let trigger_amount = this.$lodash.get(this.consolidatedLevel, 'trigger_amount', 0)
            let r  = this.consolidatedAccumulatedAmount - trigger_amount
            let rr = this.$lodash.get(this.consolidatedLevel, 'next.amount', 0) - trigger_amount
            return rr ? Math.floor((r / rr) * 100) : 0
        },
        progressFromName() {
            if (this.levelsSystem == this.$constants.getConstant('LOOKBACK_LEVELS_SYSTEM')) {
                if (this.transitionLevel && this.transitionLevel.isLast())
                    return ''

                return this.transitionLevel.name
            }

            if (!this.isLevelGuaranteed)
                return (this.$lodash.first(this.levels) || {}).name

            if (this.consolidatedLevel && this.consolidatedLevel.isLast())
                return ''

            return this.consolidatedLevel.name
        },
        progressToName() {
            if (this.levelsSystem == this.$constants.getConstant('LOOKBACK_LEVELS_SYSTEM')) {
                if (this.transitionLevel && this.transitionLevel.isLast())
                    return this.transitionLevel.name

                return this.$store.getters['levels/getLevelById'](this.transitionLevel.next.id).name
            }

            if (!this.isLevelGuaranteed)
                return this.consolidatedLevel.name

            if (this.consolidatedLevel && this.consolidatedLevel.isLast())
                return this.$lodash.last(this.levels).name

            return this.$store.getters['levels/getLevelById'](this.consolidatedLevel.next.id).name
        },
        computedText() {
            return this.isLevelGuaranteed ? this.$t('views.HomeView.levels.current_level') : this.$t('views.HomeView.levels.guarantee_level')
        },
    },
}
</script>

<style scoped>
.progress-liner-styles {
    border-radius: 3px !important;
}

.level-card {
    border-radius: .5rem;
    background: #FFF;
    height: 110px;
    padding: 1rem 1.5rem;
}

.customer-current-level {
    letter-spacing: 0.0051428571em !important;
}

@media only screen and (min-width: 960px) and (max-width: 1030px)  {
    .customer-current-level {
        letter-spacing: 0.0051428571em !important;
        font-size: 12px;
    }
    .level-icon{
        font-size: 13px !important;
    }
}

@media (min-width: 1263px) { 
    .caption{
        font-size: .8725rem !important;
    }
 }

 @media (max-width: 959px) {
    .level-card {
        height: 150px !important;
    } 
 }
</style>