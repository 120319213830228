var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-row", [
    _c(
      "section",
      { staticClass: "d-flex d-none d-sm-flex d-md-none pa-0 sx-amount mt-1" },
      [
        _c("div", { staticClass: "d-flex", attrs: { height: "30" } }, [
          _c(
            "div",
            { staticClass: "d-flex align-start mr-4" },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-2 mt-2",
                  attrs: { color: "#9A9CB8", size: 15 }
                },
                [_vm._v("$cp_dollar")]
              ),
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c(
                  "span",
                  { staticClass: "secondary_font--text points-text" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "views.HomeView.levels.level_balance.amount_balance.label"
                          )
                        ) +
                        " "
                    )
                  ]
                ),
                _c("span", { staticClass: "caption primary--text amount" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$tc(
                          "views.HomeView.levels.level_balance.amount_balance.value",
                          _vm.amountBalance
                        )
                      ) +
                      " "
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex align-start" },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-2 mt-2",
                  attrs: { color: "#9A9CB8", size: 15 }
                },
                [_vm._v("$cp_gift")]
              ),
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c(
                  "span",
                  { staticClass: "secondary_font--text points-text" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "views.HomeView.levels.level_balance.spendt_amount.label"
                          )
                        ) +
                        " "
                    )
                  ]
                ),
                _c("span", { staticClass: "caption primary--text amount" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$tc(
                          "views.HomeView.levels.level_balance.amount_balance.value",
                          _vm.spendtAmount
                        )
                      ) +
                      " "
                  )
                ])
              ])
            ],
            1
          )
        ]),
        _c(
          "div",
          {
            staticClass:
              "primary--text show-levels sx-show-levels d-flex align-center",
            on: { click: _vm.showLevels }
          },
          [
            _c("div", { staticClass: "ml-auto" }, [
              _vm._v(_vm._s(_vm.$t("views.HomeView.levels.show_levels")))
            ])
          ]
        )
      ]
    ),
    _c(
      "section",
      { staticClass: "d-none d-md-flex d-lg-flex pa-0 md-amount" },
      [
        _c("div", { staticClass: "d-flex", attrs: { height: "30" } }, [
          _c(
            "div",
            { staticClass: "d-flex align-start justify-end icon-content px-2" },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-2 mt-2",
                  attrs: { color: "#9A9CB8", size: _vm.iconSize }
                },
                [_vm._v("$cp_dollar")]
              ),
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c(
                  "span",
                  { staticClass: "secondary_font--text points-text" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "views.HomeView.levels.level_balance.amount_balance.label"
                          )
                        ) +
                        " "
                    )
                  ]
                ),
                _c("span", { staticClass: "caption primary--text amount" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$tc(
                          "views.HomeView.levels.level_balance.amount_balance.value",
                          _vm.amountBalance
                        )
                      ) +
                      " "
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex align-start justify-start icon-content" },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-2 mt-2",
                  attrs: { color: "#9A9CB8", size: _vm.iconSize }
                },
                [_vm._v("$cp_gift")]
              ),
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c(
                  "span",
                  { staticClass: "secondary_font--text points-text" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "views.HomeView.levels.level_balance.spendt_amount.label"
                          )
                        ) +
                        " "
                    )
                  ]
                ),
                _c("span", { staticClass: "caption primary--text amount" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$tc(
                          "views.HomeView.levels.level_balance.amount_balance.value",
                          _vm.spendtAmount
                        )
                      ) +
                      " "
                  )
                ])
              ])
            ],
            1
          )
        ]),
        _c(
          "div",
          {
            staticClass: "primary--text text-center show-levels px-0 mt-2",
            class: this.$vuetify.breakpoint.lgAndUp ? "mt-4" : "",
            on: { click: _vm.showLevels }
          },
          [_vm._v(_vm._s(_vm.$t("views.HomeView.levels.show_levels")) + " ")]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }